import {
  Button
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { isVanfCleef } from "helpers/constants";
import { SelectAllCheckboxProps } from "models/Forms/Forms.interface";
import { getBrandsListRecord } from "models/Store/Brands/BrandsList";
import { getClarityListRecord } from "models/Store/Jewels/Stones/ClarityList";
import { ProductStore } from "models/Store/ProductStore.interface";
import { getProductStoreMapper } from "models/Store/ProductStoreMapper";
import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { formValueSelector, reduxForm } from "redux-form";
import { themeNameSelector, userSelector } from "store/application/ApplicationSelectors";
import { STORE, STORE_SEARCH_FORM_PARAMS } from "store/models/Store.enum";
import { searchFormDataSelector } from "store/product/ProductSelectors";
import {
  SAVE_SEARCH_FORM_DATA,
  getSearchProductsList,
} from "../../../store/product/ProductActions";
import { validateSearchPanel } from "./SearchPanelValidate";
import FilterDateOfManufacture from "./filters/FilterDateOfManufacture";
import FilterDateOfSale from "./filters/FilterDateOfSale";
import FilterPrice from "./filters/FilterPrice";
import FilterStones from "./filters/FilterStones/FilterStones";
import SearchBar from "./filters/SearchBar";
import FilterCheckbox from "./filters/shared/FilterCheckbox/FilterCheckbox";
import "./searchPanel.scss";
import { useSearchPanel } from "./useSearchPanel";
import FilterAutocomplete from "./filters/shared/FilterAutoComplete/FilterAutoComplete";
import { getCreationTypesListRecord } from "models/Store/Jewels/CreationTypesList";
import { MultiSelectRecord, FilterCheckboxListProps } from "models/Filters.interface";
import { getAllAuctionsListRecord, getAuctionsListRecord, getOtherAuctionsListRecord } from "models/Store/MarketPlaces/AuctionsLists";
import { getMarketPlacesListRecord } from "models/Store/MarketPlaces/MarketPlaceList";
import { getStatusListRecord } from "models/Store/StatusList";
import { getColorGradesListRecord } from "models/Store/Jewels/Stones/ColorGradeList";
import { getCategoriesListRecord } from "models/Store/Jewels/CategoriesList";

interface SearchPanelProps {
  onCloseFilters: () => void;
  isFilterOpen: boolean;
  handleSubmit: () => void;
  clearSearchForm: () => void;
  colorGradeStateChanges: SelectAllCheckboxProps;
  clarityStateChanges: SelectAllCheckboxProps;
  auctionsStateChanges: SelectAllCheckboxProps;
  auctionsOtherStateChanges: SelectAllCheckboxProps;
  marketPlaceStateChanges: SelectAllCheckboxProps;
  categoriesStateChanges: SelectAllCheckboxProps;
  creationTypeStateChanges: SelectAllCheckboxProps;
  isStrictKeywordSelected: boolean;
  keywordValue: string;
  valid?: boolean;
}

const SearchPanel: React.FC<SearchPanelProps> = ({
  onCloseFilters,
  isFilterOpen,
  handleSubmit,
  clearSearchForm,
  colorGradeStateChanges,
  clarityStateChanges,
  auctionsStateChanges,
  auctionsOtherStateChanges,
  marketPlaceStateChanges,
  categoriesStateChanges,
  creationTypeStateChanges,
  isStrictKeywordSelected,
  keywordValue,
  valid: validForm
}) => {
  const dispatch = useDispatch();
  const { closeFilters } = useSearchPanel({ onCloseFilters, isFilterOpen })
  const themeName = useSelector(themeNameSelector);
  const {specificPermissions: {hasOtherAuctionsPermission, hasMarketPlacePermission} = false} = useSelector(userSelector);
  const isVanCleef = isVanfCleef(themeName);
  const searchFormData: ProductStore = useSelector(searchFormDataSelector);
  let hasStonePermission = true;
  let hasBrandsPermission = true;
  let checkboxComplexLists = [
    {
      title: "Other auction houses",
      checkboxList: getOtherAuctionsListRecord(),
      storeValuePrefix: STORE_SEARCH_FORM_PARAMS.AUCTIONS,
      checkboxesStateChanges: auctionsOtherStateChanges,
    }
  ];

  if (isVanCleef) {
    hasStonePermission = false;
    hasBrandsPermission = false;
  } 
  if(!hasOtherAuctionsPermission){
    checkboxComplexLists = [];
  }

  useEffect(() => {
    if (keywordValue) {
      if (!isStrictKeywordSelected) {
        dispatch({ type: SAVE_SEARCH_FORM_DATA, ...searchFormData, sort_by: "-" });
      } else {
        dispatch({
          type: SAVE_SEARCH_FORM_DATA,
          ...searchFormData,
          sort_by: "sort_by=date&order=desc&",
        });
      }
    }
  }, [isStrictKeywordSelected, keywordValue, dispatch]);

  return (
    <div className="search-panel">
      <form onSubmit={handleSubmit}>
        <div className="search-panel__wrap search-form-wrap">
          <div className="search-panel__top">
            <Button
              className="vca-btn vca-btn--secondary"
              variant="outlined"
              onClick={() => {
                clearSearchForm();
              }}
            >
              {"Clear All"}
            </Button>
            <div
              className="search-panel__close"
              onClick={() => closeFilters(true)}
            >
              Close{" "}
              <span className="close-icon">
                <CloseIcon />
              </span>
            </div>
          </div>

          <SearchBar />

          {/* Brands */}
          {hasBrandsPermission && (
            <FilterAutocomplete
              multiple
              title="Brands"
              borderBottom
              storeValuePrefix={STORE_SEARCH_FORM_PARAMS.BRANDS}
              placeholder="Brands"
              recordList={getBrandsListRecord()}
            />
          )}


          {/* Categories */}
          <FilterCheckbox
            borderBottom
            title="Categories"
            checkboxList={getCategoriesListRecord()}
            checkboxesStateChanges={categoriesStateChanges}
            storeValuePrefix={STORE_SEARCH_FORM_PARAMS.CATAGORIES}
            selectAll
            storeFormName={STORE.SEARCH_FORM}
          />

          {/* Creation Type */}
          <FilterCheckbox
            borderBottom
            title="Creation Types"
            checkboxList={getCreationTypesListRecord()}
            checkboxesStateChanges={creationTypeStateChanges}
            storeValuePrefix={STORE_SEARCH_FORM_PARAMS.CREATION_TYPES}
            selectAll
            storeFormName={STORE.SEARCH_FORM}
          />

          {/* Stones */}
         {hasStonePermission &&
            <FilterStones
              borderBottom
              checkboxesColorGradeStateChanges={colorGradeStateChanges}
              checkboxesClarityStateChanges={clarityStateChanges}
            />
          }

          {/* Auctions */}
          <FilterCheckbox
            borderBottom
            title="Auctions"
            checkboxList={getAuctionsListRecord()}
            checkboxListForSelectAll={getAllAuctionsListRecord(hasOtherAuctionsPermission)}
            checkboxComplexLists={checkboxComplexLists}
            checkboxesStateChanges={auctionsStateChanges}
            storeValuePrefix={STORE_SEARCH_FORM_PARAMS.AUCTIONS}
            selectAll
            storeFormName={STORE.SEARCH_FORM}
          />

          {/* Marketplace */}
          {hasMarketPlacePermission && (
            <FilterCheckbox
              borderBottom
              title="Marketplaces"
              checkboxList={getMarketPlacesListRecord()}
              checkboxesStateChanges={marketPlaceStateChanges}
              storeValuePrefix={STORE_SEARCH_FORM_PARAMS.MARKETPLACES}
              selectAll
              storeFormName={STORE.SEARCH_FORM}
            />
          )}

          {/* Status */}
          <FilterCheckbox
            borderBottom
            title="Status"
            checkboxList={getStatusListRecord()}
            storeValuePrefix={STORE_SEARCH_FORM_PARAMS.STATUS}
            storeFormName={STORE.SEARCH_FORM}
          />

          <FilterPrice borderBottom />

          <FilterDateOfSale borderBottom />

          <FilterDateOfManufacture />

        </div>
        <div className="search-panel__wrap search-button-container vca-container-page">
          <div className="wrap-btn center search-button">
            <Button
              className="vca-btn"
              variant="outlined"
              type="submit"
              onClick={() => closeFilters(false)}
              disabled={!validForm}
            >
              {"Search"}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};


const formConfig = {
  form: STORE.SEARCH_FORM,
  validate: validateSearchPanel,
  onSubmit: (values: ProductStore, dispatch: any) => {

    const dateMin = values?.date?.date_min ?? undefined;
    const dateMax = values?.date?.date_max ?? undefined;

    const valuesToSeach: ProductStore = {
      ...values,
      date: {
        date_min: dateMin,
        date_max: dateMax,
      }
    }

    const productStore: ProductStore = getProductStoreMapper(valuesToSeach)


    dispatch(
      getSearchProductsList.request(productStore)
    );
  },
};

const decoratedSearchPanel = reduxForm<any, any>(formConfig)(SearchPanel);

const getCheckboxAllValues = (state: any, list: MultiSelectRecord, prefixSelector: string): SelectAllCheckboxProps => {
  let isCheckboxSelected: boolean;
  let isCheckboxIndeterminate = false;
  prefixSelector = `${prefixSelector}.`
  const isListChecked: boolean = Object.values(list)?.reduce((acc: boolean, val: FilterCheckboxListProps) => {
    const isChecked = !!selector(state, `${prefixSelector}${val.paramName}`);
    acc = typeof acc == "boolean" ? acc : isChecked;
    if (acc !== isChecked && !isCheckboxIndeterminate) {
      isCheckboxIndeterminate = true;
    }
    return !!isChecked;
  }, undefined);

  if (isCheckboxIndeterminate) {
    isCheckboxSelected = false;
  } else {
    isCheckboxSelected = isListChecked;
  }

  return {
    isIndeterminate: isCheckboxIndeterminate,
    isSelected: isCheckboxSelected,
  }
}

const connectedSearchPanel = connect(
  (state) => {
    const isStrictKeywordSelected = selector(state, "search_mode");
    const keywordValue = selector(state, "keyword");
    const hasOtherAuctionsPermission = (state as any).application?.user?.specificPermissions?.hasOtherAuctionsPermission;

    return {
      isStrictKeywordSelected: isStrictKeywordSelected,
      keywordValue: keywordValue,
      colorGradeStateChanges: getCheckboxAllValues(state, getColorGradesListRecord(), STORE_SEARCH_FORM_PARAMS.COLOR_GRADE),
      clarityStateChanges: getCheckboxAllValues(state, getClarityListRecord(), STORE_SEARCH_FORM_PARAMS.CLARITY),
      auctionsStateChanges: getCheckboxAllValues(state, getAllAuctionsListRecord(hasOtherAuctionsPermission), STORE_SEARCH_FORM_PARAMS.AUCTIONS),
      auctionsOtherStateChanges: getCheckboxAllValues(state, getOtherAuctionsListRecord(), STORE_SEARCH_FORM_PARAMS.AUCTIONS),
      marketPlaceStateChanges: getCheckboxAllValues(state, getMarketPlacesListRecord(), STORE_SEARCH_FORM_PARAMS.MARKETPLACES),
      categoriesStateChanges: getCheckboxAllValues(state, getCategoriesListRecord(), STORE_SEARCH_FORM_PARAMS.CATAGORIES),
      creationTypeStateChanges: getCheckboxAllValues(state, getCreationTypesListRecord(), STORE_SEARCH_FORM_PARAMS.CREATION_TYPES),
    };
  }
)(decoratedSearchPanel);

// Decorate with connect to read form values
const selector = formValueSelector(STORE.SEARCH_FORM); // <-- same as form name

export default connectedSearchPanel;
